import React from 'react';
import { FaLinkedin, FaGithub, FaTwitter} from 'react-icons/fa';
import { MdCopyright } from 'react-icons/md';
import copyRightYear from '../util/Year';
const Footer = () => {
	const myYear = copyRightYear();
	return (
		<div className='footer'>
			<div className='footer-content'>
				<div className='socail-media'>
					<div className='footer-title'>Connect</div>
					<div className='icons'>
						<div className='linkedin'>
							<a
								href='https://www.linkedin.com/in/shahristani/'
								rel='noreferrer'
								target='_blank'
							>
								<FaLinkedin className='linkedinIcon' />
							</a>
						</div>
						<div className='twitter'>
							<a
								href='https://twitter.com/Ishahristani'
								rel='noreferrer'
								target='_blank'
							>
								{' '}
								<FaTwitter className='twitterIcon' />
							</a>
						</div>
						<div className='github'>
							<a
								href='https://github.com/Ibrahimshahristani'
								rel='noreferrer'
								target='_blank'
							>
								<FaGithub className='githubIcon' />
							</a>{' '}
						</div>
					</div>
				</div>
				<div className='copy-right'>
					<div className='copyRightIcon'>
						<MdCopyright />
					</div>
					<p className='Year'>{`${myYear} Ibrahim Shahristani`}</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
