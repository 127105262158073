import React from 'react'
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'urql';
import { POST_QUERY } from '../lib/blog';
const PostDetails = () => {

  const { slug } = useParams();
  const [blogs] = useQuery({ query: POST_QUERY });
  const { data, fetching, error } = blogs;
  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Oh noo. {error.message}</p>;
  const reuslts = data.blogs.data;

  const blog = reuslts.find(
    (blog) => blog.attributes.slug === slug
  );

  return (
    <div key={blog.attributes.slug} className="blog-Details">
      <div className='blog-title' id="title-detials">
        {blog.attributes.title}
      </div>

      <div className='blog-body'>
        <ReactMarkdown>
          {blog.attributes.body}
        </ReactMarkdown>

      </div>
    </div>

  )
}

export default PostDetails