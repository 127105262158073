export const POST_QUERY = `
query{
  blogs{
    data{
      attributes{
        title
        body
        slug
        dateCreated
        image{
          data{
            attributes{
              formats
            }
          }
        }
      }
    }
  }
}`;
