import React from 'react';
import { useQuery } from 'urql';
import { TECHNOLOGY_PROJECTS } from '../lib/projectTech';
import { useParams, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { MdOutlineArrowBack } from 'react-icons/md';

const Technologies = () => {
	const { slug } = useParams();
	const [technologies] = useQuery({
		query: TECHNOLOGY_PROJECTS,
		variables: { slug: slug },
	});
	const { data, fetching, error } = technologies;

	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Oh noo. {error.message}</p>;

	return (
		<div className='contianer'>
			{data.technologies.data.map((tech) => (
				<div key={tech.attributes.slug}>
					<div id='projectByTechnology' className='contact-title'>
						{tech.attributes.name} Projects
						<div>
							<Link to={`/projects`}>
								<Button variant='light'>
									{' '}
									<MdOutlineArrowBack className='backbtn' />
									Back to projects{' '}
								</Button>
							</Link>
						</div>
					</div>

					<div className='projectTechnology'>
						{tech.attributes.projects.data.map((proj) => (
							// <div>{proj.attributes.title}</div>

							<div className='card-content' key={proj.attributes.slug}>
								<div className='imag-coainter'>
									<img
										src={
											proj.attributes.image.data.attributes.formats.thumbnail
												.url
										}
										alt='shahristani phot'
										className='projectImg'
									/>
									<div className='project-profile'>
										<div className='project-title'>{proj.attributes.title}</div>
										<div className='description'>
											{proj.attributes.description.substring(0, 130)}{' '}
											<Link to={`/projects/${proj.attributes.slug}`}>
												<button className='learn-more'>
													<span className='circle' aria-hidden='true'>
														<span className='icon arrow'></span>
													</span>
													<span className='button-text'>Read More</span>
												</button>
											</Link>
										</div>
									</div>
								</div>
								<div className='links'>
									<a className='project-github' href={proj.attributes.githubUrl} rel='noreferrer' target='_blank'>
										GitHub
									</a>
									<a className='project-live' href={proj.attributes.url} rel='noreferrer' target='_blank'>
										Live
									</a>
								</div>
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
};

export default Technologies;
