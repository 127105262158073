import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { MdLocationPin, MdEmail } from 'react-icons/md';
import { BsTelephoneFill } from 'react-icons/bs';
import Swal from 'sweetalert2';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_ServiceId, process.env.REACT_APP_TemplateId, form.current, process.env.REACT_APP_PublicId)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
        })
      });
    e.target.reset();
  };





  return (
    <div className='contact'>
      <div className='contact-title'>
        Get In Touch <br /> <span className='underline'></span>
      </div>
      <div className='form-elements'>
        <div className='contact-form'>
          <div className='contact-info'>
            <p>
              {' '}
              <span className='name'>I'd love to hear from you!</span>
            </p>
            <div className='email'>
              <MdEmail className='emailIcon' />
              <a href='mailto:e.farhang@gmail.com' className='emailText'>
                e.farhang@gmail.com
              </a>
            </div>
            <div className='phone'>
              <BsTelephoneFill className='phoneIcon' />
              <a href='tel:+17785120203' className='phoneText'>
                (778) 512-0203
              </a>
            </div>
            <div className='myLocation'>
              <MdLocationPin className='locationIcon' />
              <span className='location'>-Vancouver, British Columbia</span>
            </div>
          </div>
        </div>
        <div className='form-container'>
          <form ref={form} onSubmit={sendEmail}>
            <label htmlFor='name'> Name*</label>
            <input type="text" name="user_name" id="name" required />
            <label htmlFor='email'>Email Address*</label>
            <input type="email" name="user_email" id="email" required />
            <label htmlFor='subject'>Subject*</label>
            <input type="text" name="subject" id="subject" required />
            <label htmlFor='message'>Message*</label>
            <textarea name="message" id='message'
              maxLength={400}
              required />
            <input type="submit" value="Send" />
          </form>
        </div>
      </div>
    </div>

  );
};
export default Contact;
