import React from 'react';
import { useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
// import { projects } from '../data';
import Card from 'react-bootstrap/Card';
// import ListGroup from 'react-bootstrap/ListGroup';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { useQuery } from 'urql';
import { PROJECT_QUERY } from '../lib/query';
const ProjectDetials = () => {
	const { slug } = useParams();
	const [results] = useQuery({ query: PROJECT_QUERY });
	const { data, fetching, error } = results;
	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Oh noo. {error.message}</p>;
	const projects = data.projects.data;

	const project = projects.find(
		(project) => project.attributes.slug === slug
	);

	return (
		<div className='contianer'>
			<div id='technologies' className='blog-title'>
				{project.attributes.title} <br />
				<div>
					<Link to={`/projects`}>
						<Button variant='light'>
							{' '}
							<MdOutlineArrowBack className='backbtn' />
							Back to projects{' '}
						</Button>
					</Link>
				</div>
			</div>
			<div className='blog-title'>Description</div>

			<ReactMarkdown>
				{project.attributes.description}
			</ReactMarkdown>


			<div className='blog-title'>Information Architecture</div>
			<img
				className='d-block w-100 h-100'
				src={project.attributes.Er.data[0].attributes.formats.small.url}
				alt='First slide'
				id='er'
			/>


			<div className='blog-title'>Features and Installation</div>
			<div className='mainCard'>
				<Card border='Info' className='features'>
					<Card.Header>Features</Card.Header>
					<Card.Body>
						<Card.Text>
							<ReactMarkdown>
								{project.attributes.Features}
							</ReactMarkdown>

						</Card.Text>
					</Card.Body>
				</Card>

				<Card border='Secondary' className='features'>
					<Card.Header>How to use</Card.Header>
					<Card.Body>
						<Card.Text>
							<ReactMarkdown>
								{project.attributes.HowToUse}
							</ReactMarkdown>
						</Card.Text>
					</Card.Body>
				</Card>
			</div>

			<div className='blog-title'>Pages</div>

			<Carousel variant='ligth'>
				<Carousel.Item>
					<img
						className='d-block w-100'
						src={project.attributes.image.data.attributes.formats.thumbnail.url}
						alt='First slide'
					/>
					<Carousel.Caption>
						<h5>	{project.attributes.title}</h5>
						<p>	{project.attributes.description.substring(0, 100)}</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className='d-block w-100'
						src={project.attributes.image.data.attributes.formats.medium.url}
						alt='Second slide'
					/>
					<Carousel.Caption>
						<h5>	{project.attributes.title}</h5>
						<p>	{project.attributes.description.substring(0, 100)}</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className='d-block w-100'
						src={project.attributes.image.data.attributes.formats.medium.url}
						alt='Third slide'
					/>
					<Carousel.Caption>
						<h5>	{project.attributes.title}</h5>
						<p>
							{project.attributes.description.substring(0, 100)}
						</p>
					</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
		</div>
	);
};

export default ProjectDetials;
