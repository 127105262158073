export const PROJECT_QUERY = `
query{
  projects{
    data{
      attributes{
        slug
        title
        url
        githubUrl
        HowToUse
        Features
        description 
        technologies{
          data{
          	attributes{
              name
            }
          }
        }
        Er{
          data{
            attributes{
              formats
            }
          }
        }
        screenShoot{
          data{
            attributes{
              formats
            }
          }
        }
        image{
          data{
            attributes{
              formats
            }
          }
        }
      }
    }
  }
}
`;
