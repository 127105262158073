import React from 'react';
import { Link } from 'react-router-dom';
import Ibrahimphoto from '../img/ibrahim.png';
const Home: React.FC = () => {
	return (
		<div className='home-page'>
			<div className='bio'>
				<div className='bio-info'>
					<p>
						Hi, my name is <span className='name'>Ibrahim Shahristani</span>, I
						am a full-stack web developer. I have M.Sc. in computer science and
						recently graduated from Software System Developer program of BCIT.
					</p>
					<p>
						Eager to learn new technologies and embrace challenges with a growth
						mindset.
					</p>
					<p className='location'>-Based in Vancouver, British Columbia</p>
					<div className='btnMain'>
						<Link to='/contact'>
							{' '}
							<button className='button'>GET IN TOUCH</button>
						</Link>
						<Link to='/projects'>
							{' '}
							<button className='buttonp'>PROJECTS</button>
						</Link>
					</div>
				</div>
				<div className='bio-img'>
					<img src={Ibrahimphoto} alt='shahristani phot' className='myImg' />
				</div>
			</div>

			<div className='background'>
				<div className='eudcation'>
					<div className='background-title'>Education</div>
					<span className='underline'></span>
					<br />
					<div className='background-text'>Technical University of Berlin</div>
					<div className='bgText'>M.Sc. in Computer Science </div>
					<div className='bgText'>Feb 2010</div>
					<br />

					<div className='background-text'>
						British Columbia Institute of Technology (BCIT)
					</div>
					<div className='bgText'>Certificate, Software System Developer </div>
					<div className='bgText'>May 2020</div>
					<br />
					<div className='background-text'>
						Syracuse University, Maxwell School, NY
					</div>
					<div className='bgText'>
						Humphrey Fellowship (Technology Management)
					</div>
					<div className='bgText'>June 2018</div>
					<br />
				</div>
				<div className='eudcation'>
					<div className='background-title'>Experience</div>
					<span className='underline'></span>
					<br />
					<div className='background-text'>API Solutions</div>
					<div className='bgText'>Web Developer </div>
					<div className='bgText'>June 2020 - present</div> <br />
					<div className='background-text'>Unique Telecommunications Inc </div>
					<div className='bgText'>Fiber Optic Splicer </div>
					<div className='bgText'>Nov 2018 – Sept 2019</div> <br />
					<div className='background-text'>AIHRC </div>
					<div className='bgText'>Software Developer </div>
					<div className='bgText'>April 2012 - May 2014</div>
				</div>
				<div className='eudcation'>
					<div className='background-title'>Skills</div>
					<span className='underline'></span>
					<div className='background-text'>Front-End Web Development </div>
					<div className='bgText'>
						HTML, CSS, JavaScript, React(TypeScript){' '}
					</div>
					<br />
					<div className='background-text'>Back-End Development</div>
					<div className='bgText'>Web API, Node js, C#.NET, ASP.NET</div>
					<br />
					<div className='background-text'>Databases</div>
					<div className='bgText'>MS SQL Server, MySQL & MongoDB</div>
					<br />
					<div className='background-text'>Deployment & Development tools</div>
					<div className='bgText'>GIT, GitHub</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
