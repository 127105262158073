import React from 'react'
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';
import { POST_QUERY } from '../lib/blog';

const Posts = () => {
  const [blogs] = useQuery({ query: POST_QUERY });
  const { data, fetching, error } = blogs;
  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Oh noo. {error.message}</p>;
  const reuslts = data.blogs.data;

  return (


    <div className='blog-container'>
      {reuslts.map((post) => (
        <div key={post.attributes.slug} className="blog" >
          <div className='blog-cotent'>
            <Link to={`/blog/${post.attributes.slug}`}>
              <div className='blog-title'>
                {post.attributes.title}
              </div>
            </Link>
            <span className='location'>{post.attributes.dateCreated}
            </span>
            <div className='blog-body'>

              {post.attributes.body.substring(0, 190)}

            </div>
            <div>
              <Link to={`/blog/${post.attributes.slug}`}>
                {' '}
                <button className='button'>Read More</button>
              </Link>
            </div>
          </div>
          <div className='blog-image'>
            <img
              src={
                post.attributes.image.data.attributes.formats.thumbnail.url
              }
              alt='shahristani phot'
              className='projectImg'
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Posts