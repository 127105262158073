import React from 'react';
import { Link } from 'react-router-dom';
import Technology from '../components/Technology';
import { useQuery } from 'urql';
import { PROJECT_QUERY } from '../lib/query';

const Projects = () => {
	const [results] = useQuery({ query: PROJECT_QUERY });
	const { data, fetching, error } = results;
	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Oh noo. {error.message}</p>;
	const projects = data.projects.data;

	return (
		<div className='project-container'>
			<div className='top-section'>
				<div id='project-title' className='secion-title'>
					Projects <br />
					<span className='underlinePro'></span>
				</div>
				<div className='filterBy'>
					<Technology />
				</div>
			</div>

			<div className='project'>
				{projects.map((project) => (
					<div className='card-content' key={project.attributes.slug}>
						<div className='imag-coainter'>
							<Link to={`/projects/${project.attributes.slug}`}>
							<img
								src={
									project.attributes.image.data.attributes.formats.thumbnail.url
								}
								alt='shahristani phot'
								className='projectImg'
							/>
							</Link>
							<div className='project-profile'>
								<div className='project-title'>{project.attributes.title}</div>
								<div className='description'>
							
										{project.attributes.description.substring(0, 100)}
							
									<Link to={`/projects/${project.attributes.slug}`}>
										<button className='learn-more'>
											<span className='circle' aria-hidden='true'>
												<span className='icon arrow'></span>
											</span>
											<span className='button-text'>Read More</span>
										</button>
									</Link>
								</div>
							</div>
						</div>
						<div className='links'>
							<a className='project-github' href={project.attributes.githubUrl} rel='noreferrer' target='_blank' >
								GitHub
							</a>

							<a className='project-live' href={project.attributes.url} rel='noreferrer' target='_blank'>
								Live
							</a>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
export default Projects;
