import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, NavLink } from 'react-router-dom';
import Ibrahimphoto from '../img/logoin.png';
import Image from 'react-bootstrap/Image';
import { MdOutlineLightMode, MdOutlineNightlight } from 'react-icons/md';

const Header = (props: any) => {
	const [toggleName, setToggleName] = useState<Boolean>(false);
	// const [toggleIcon, setToggleIcon] = useState<Boolean>(false);

	function dropDownResponsive() {
		const el: any = document.getElementById('nav');
		el.classList.toggle('responsive');
		setToggleName((toggleName) => !toggleName);
	}
	function closeMenu() {
		const el: any = document.getElementById('nav');
		if (el.classList.contains('responsive')) {
			el.classList.remove('responsive');
			setToggleName((toggleName) => !toggleName);
		}
	}
	return (
		<div className='header'>
			<div className='header-content'>
				<div className='logo'>
					<Link to='/' className='linkLogo'>
						Ibrahim Shahristani
					</Link>
					<Link to='/' className='link'>
						<Image
							src={Ibrahimphoto}
							alt='shahristaniphoto'
							className='profileImg img-rounded'
						/>
					</Link>
				</div>
				<button className='mobile-menu' onClick={dropDownResponsive}>
					{toggleName ? <AiOutlineClose /> : <FaBars />}
				</button>
				<div id='nav' className='nav-menu' onClick={closeMenu}>
					<ul>
						<li>
							<NavLink
								to='/'
								className={({ isActive }) =>
									isActive ? 'link active' : 'link'
								}
							>
								About
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/projects'
								className={({ isActive }) =>
									isActive ? 'link active' : 'link'
								}
							>
								Projects
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/blog'
								className={({ isActive }) =>
									isActive ? 'link active' : 'link'
								}
							>
								Blog
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/contact'
								className={({ isActive }) =>
									isActive ? 'link active' : 'link'
								}
							>
								Contact
							</NavLink>
						</li>
						<li>
							<button id='ligthIcon' onClick={props.toggleTheme}>
								{props.theme === 'light' ? (
									<MdOutlineLightMode className='lightMode' />
								) : (
									<MdOutlineNightlight className='lightMode' />
								)}
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Header;
