import React, { useState, createContext } from 'react';
import './style.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './Pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import ProjectDetials from './Pages/ProjectDetials';
import Technologies from './Pages/Technologies';
import { Provider, createClient } from 'urql';
import Posts from './Pages/Posts';
import PostDetails from './Pages/PostDetails';
// const myURL: string | any = process.env.PUBLIC_URL_BASEURL
const client = createClient({ url: process.env.REACT_APP_BASEURL });
export const ThemeContext = createContext(null)
function App() {
	const [theme, setTheme] = useState("light");
	const toggleTheme = () => {
		setTheme((currentTheme) => (currentTheme === "light" ? "dark" : "light"))
	}
	return (
		<Provider value={client}>
			<ThemeContext.Provider value={{ theme, toggleTheme }}>
				<div id={theme}>
					<BrowserRouter>
						<Header theme={theme} toggleTheme={toggleTheme} />
						<div className='containerApp'>
							<Routes>
								<Route path='/' element={<Home />} />
								<Route path='/projects' element={<Projects />} />
								<Route path='/projects/:slug' element={<ProjectDetials />} />
								<Route path='/technologies/:slug' element={<Technologies />} />
								<Route path='/blog' element={<Posts />} />
								<Route path='/blog/:slug' element={<PostDetails />} />

								<Route path='/contact' element={<Contact />} />
								<Route
									path='*'
									element={
										<main style={{ padding: '1rem' }}>
											<p>There's nothing here!</p>
										</main>
									}
								/>
							</Routes>
						</div>
						<Footer />
					</BrowserRouter>
				</div>
			</ThemeContext.Provider>
		</Provider>
	);
}

export default App;
