export const TECHNOLOGY_QUERY = `
query{
    technologies{
      data{
        attributes{
          name
          slug
         }
      }
  }
}
`;
