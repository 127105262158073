export const TECHNOLOGY_PROJECTS = `
	query getTechnologyProject($slug: String!) {
		technologies(filters:{slug:{eq: $slug}}) {
			data {
				attributes {
					name
					slug
					projects{
						data {
							attributes {
								slug
								title
								url
								githubUrl
								description
								image {
									data {
										attributes {
											formats
										}
									}
								}
								technologies {
									data {
										attributes {
											name
											slug
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
