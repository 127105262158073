import React from 'react';
import { useQuery } from 'urql';
import Button from 'react-bootstrap/Button';

import { Link } from 'react-router-dom';

import { TECHNOLOGY_QUERY } from '../lib/technology';
const Technology = () => {
	const [technologies] = useQuery({ query: TECHNOLOGY_QUERY });
	const { data, fetching, error } = technologies;
	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Oh noo. {error.message}</p>;
	const techs = data.technologies.data;

	return (
		<div className='usedTech'>
			<span className='usedTechText'>Projects by Technologies: </span>
			{techs.map((tech) => (
				<Button variant="light" className='btnTchnology'key={tech.attributes.slug}>
					<Link className="techCategory"
						to={`/technologies/${tech.attributes.slug}` }
					>
						{tech.attributes.name}
					</Link>
				</Button>
			))}
		</div>
	);
};

export default Technology;
